<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12" v-if="loading">
          <div class="loadingBox">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col md="12" v-if="!loading">
          <h1 class="systemTitle">User Logs</h1>
          <ul class="loglist">
            <li class="headingSection">
              <div>S/N</div>
              <div>Conntect To</div>
              <div>Action</div>
              <div>Action Model</div>
              <!-- <div>Action Description</div> -->
              <div>User Handle</div>

              <div>Created At</div>
            </li>
            <li v-for="(ele, i) in logList" :key="i + 'log'">
              <div>{{ i + 1 }}</div>
              <div>
                <router-link
                  :to="`/product/${ele.connectedTo._id}`"
                  v-if="ele.actionModel == 'product'"
                  >{{ ele.connectedTo.productName }}</router-link
                >
                <router-link
                  :to="`/blog/${ele.connectedTo._id}`"
                  v-if="ele.actionModel == 'blog'"
                  >{{ ele.connectedTo.blogTitle }}</router-link
                >
              </div>
              <div class="text-uppercase">{{ ele.action }}</div>
              <div>{{ ele.actionModel }}</div>
              <!-- <div>{{ ele.actionDescription }}</div> -->
              <div>{{ ele.user.fullname }}</div>

              <div>{{ new Date() | moment("dddd, MMMM Do YYYY") }}</div>
            </li>
          </ul>
          <ul class="pagination">
            <li>
              <v-icon @click="prevAct">mdi-chevron-left</v-icon>
            </li>
            <li>{{ pageNumber }} of {{ allLogs.pagination.total }}</li>
            <li>
              <v-icon @click="nextAct">mdi-chevron-right</v-icon>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Log",
  data: () => {
    return {
      logList: [],
      page: 1,
      limit: 30,
      loading: true,
      pageNumber:undefined
    };
  },
  methods: {
    ...mapActions(["getLogList"]),
    async nextAct() {
      this.loading = true;
      this.page = this.page + 1;
      await this.getLogList({ page: this.page, limit: this.limit });
      this.logList = this.allLogs.results;
      this.loading = false;
      this.pageNumber = this.page * this.limit < this.allLogs.pagination.total ? this.page * this.limit : this.allLogs.pagination.total;
    },
    async prevAct() {
      this.loading = true;
      this.page = this.page - 1;
      await this.getLogList({ page: this.page, limit: this.limit });
      this.logList = this.allLogs.results;
      this.loading = false;
      this.pageNumber = this.page * this.limit < this.allLogs.pagination.total ? this.page * this.limit : this.allLogs.pagination.total;
    },
  },
  computed: {
    ...mapGetters(["allLogs"]),
  },
  async created() {
    await this.getLogList({ page: this.page, limit: this.limit });
    this.logList = this.allLogs.results;
    this.loading = false;
    this.pageNumber = this.page * this.limit < this.allLogs.pagination.total ? this.page * this.limit : this.allLogs.pagination.total;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.loglist {
  padding-left: 0;
  margin-left: 0;
  border: 1px solid #000;
  li {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid #ccc;
    div {
      border: 0.01px solid rgb(238, 237, 237);
      padding: 10px;
    }
    font-size: 14px;
  }
}
.headingSection {
  font-weight: 700;
  font-size: 18px;
  div {
    padding: 20px 10px !important;
  }
}
.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  li {
    padding: 10px;
    cursor: pointer;
  }
}
.loadingBox{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>